#tsparticles {
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
}

#tsparticles canvas {
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 0.8s ease, -webkit-transform 1.4s ease;
  transition: opacity 0.8s ease, transform 1.4s ease;
}

canvas {
  display: block;
  position: fixed;
  z-index: -1;
}
.izy_rehetra {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d5f2f1;
}

.tuile {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  cursor: pointer;
  z-index: 6;
  transition: all 0.5s ease;
}

.tuile:hover {
  transform: scale(1.05);
}
div.Table_01 {
  position: relative;
  left: 0px;
  top: 0px;
  width: 1519px;
  height: 7858px;
}

.logoCDV {
  position: absolute;
  left: 230px;
  top: 100px;
  width: 500px;
  height: auto;
  cursor: pointer;
  z-index: 10;
}

.logoCDV:hover {
  opacity: 0.9;
}
div.road-map-01 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: auto;
  height: auto;
}

div.road-map-02 {
  position: absolute;
  left: 0px;
  top: 384px;
  width: auto;
  height: auto;
}
.efa_lasa {
  background: rgba(0, 0, 0, 0.5);
}

div.road-map-03 .part1 {
  position: absolute;
  left: 944px;
  top: 384px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_03.jpg);
}

div.road-map-03 .done1 {
  position: absolute;
  left: 944px;
  top: 384px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-1.jpg);
}

div.road-map-04 {
  position: absolute;
  left: 1389px;
  top: 384px;
  width: 130px;
  height: 2510px;
}

div.road-map-05 {
  position: absolute;
  left: 944px;
  top: 670px;
  width: 445px;
  height: 225px;
}

div.road-map-06 {
  position: absolute;
  left: 0px;
  top: 892px;
  width: 241px;
  height: 401px;
}

div.road-map-07 .part3 {
  position: absolute;
  left: 241px;
  top: 892px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_07.jpg);
}

div.road-map-07 .done3 {
  position: absolute;
  left: 241px;
  top: 892px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_box-2.jpg);
}

div.road-map-07 .current3 {
  position: absolute;
  left: 241px;
  top: 892px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_07.png);
  background-color: #4d8b3e;
}

div.road-map-08 {
  position: absolute;
  left: 687px;
  top: 892px;
  width: 257px;
  height: 619px;
}

div.road-map-09 .part2 {
  position: absolute;
  left: 944px;
  top: 895px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_09.jpg);
}

div.road-map-09 .current2 {
  position: absolute;
  left: 944px;
  top: 895px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_09.png);
  background-color: #4d8b3e;
}

div.road-map-09 .done2 {
  position: absolute;
  left: 944px;
  top: 895px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-3.jpg);
}

div.road-map-10 {
  position: absolute;
  left: 241px;
  top: 1178px;
  width: 446px;
  height: 115px;
}

div.road-map-11 {
  position: absolute;
  left: 944px;
  top: 1181px;
  width: 445px;
  height: 330px;
}

div.road-map-12 {
  position: absolute;
  left: 0px;
  top: 1293px;
  width: 149px;
  height: 1074px;
}

div.road-map-13 .part4 {
  position: absolute;
  left: 149px;
  top: 1293px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_13.jpg);
}

div.road-map-13 .current4 {
  position: absolute;
  left: 149px;
  top: 1293px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_13.png);
  background-color: #4d8b3e;
}

div.road-map-13 .done4 {
  position: absolute;
  left: 149px;
  top: 1293px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-4.jpg);
}

div.road-map-14 {
  position: absolute;
  left: 594px;
  top: 1293px;
  width: 93px;
  height: 698px;
}

div.road-map-15 {
  position: absolute;
  left: 687px;
  top: 1511px;
  width: 248px;
  height: 480px;
}

div.road-map-16 .part5 {
  position: absolute;
  left: 935px;
  top: 1511px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_16.jpg);
}

div.road-map-16 .current5 {
  position: absolute;
  left: 935px;
  top: 1511px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_16.png);
  background-color: #4d8b3e;
}

div.road-map-16 .done5 {
  position: absolute;
  left: 935px;
  top: 1511px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_box-5.jpg);
}

div.road-map-17 {
  position: absolute;
  left: 1381px;
  top: 1511px;
  width: 8px;
  height: 1383px;
}

div.road-map-18 {
  position: absolute;
  left: 149px;
  top: 1579px;
  width: 445px;
  height: 412px;
}

div.road-map-19 {
  position: absolute;
  left: 935px;
  top: 1797px;
  width: 446px;
  height: 194px;
}

div.road-map-20 {
  position: absolute;
  left: 149px;
  top: 1991px;
  width: 75px;
  height: 376px;
}

div.road-map-21 .part7 {
  position: absolute;
  left: 224px;
  top: 1991px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_21.jpg);
}

div.road-map-21 .current7 {
  position: absolute;
  left: 224px;
  top: 1991px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_21.png);
  background-color: #4d8b3e;
}

div.road-map-21 .done7 {
  position: absolute;
  left: 224px;
  top: 1991px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-6.jpg);
}

div.road-map-22 {
  position: absolute;
  left: 669px;
  top: 1991px;
  width: 263px;
  height: 587px;
}

div.road-map-23 .part6 {
  position: absolute;
  left: 932px;
  top: 1991px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_23.jpg);
}

div.road-map-23 .current6 {
  position: absolute;
  left: 932px;
  top: 1991px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_23.png);
  background-color: #4d8b3e;
}

div.road-map-23 .done6 {
  position: absolute;
  left: 932px;
  top: 1991px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_box-7.jpg);
}

div.road-map-24 {
  position: absolute;
  left: 1378px;
  top: 1991px;
  width: 3px;
  height: 903px;
}

div.road-map-25 {
  position: absolute;
  left: 224px;
  top: 2277px;
  width: 445px;
  height: 90px;
}

div.road-map-26 {
  position: absolute;
  left: 932px;
  top: 2277px;
  width: 446px;
  height: 301px;
}

div.road-map-27 {
  position: absolute;
  left: 0px;
  top: 2367px;
  width: 60px;
  height: 5491px;
}

div.road-map-28 .part8 {
  position: absolute;
  left: 60px;
  top: 2367px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_28.jpg);
}

div.road-map-28 .current8 {
  position: absolute;
  left: 60px;
  top: 2367px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_28.png);
  background-color: #4d8b3e;
}

div.road-map-28 .done8 {
  position: absolute;
  left: 60px;
  top: 2367px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_box-8.jpg);
}

div.road-map-29 {
  position: absolute;
  left: 506px;
  top: 2367px;
  width: 163px;
  height: 211px;
}

div.road-map-30 {
  position: absolute;
  left: 506px;
  top: 2578px;
  width: 88px;
  height: 415px;
}

div.road-map-31 .part9 {
  position: absolute;
  left: 594px;
  top: 2578px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_31.jpg);
}

div.road-map-31 .current9 {
  position: absolute;
  left: 594px;
  top: 2578px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_31.png);
  background-color: #4d8b3e;
}

div.road-map-31 .done9 {
  position: absolute;
  left: 594px;
  top: 2578px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-9.jpg);
}

div.road-map-32 {
  position: absolute;
  left: 1039px;
  top: 2578px;
  width: 339px;
  height: 316px;
}

div.road-map-33 {
  position: absolute;
  left: 60px;
  top: 2653px;
  width: 446px;
  height: 340px;
}

div.road-map-34 {
  position: absolute;
  left: 594px;
  top: 2864px;
  width: 445px;
  height: 30px;
}

div.road-map-35 {
  position: absolute;
  left: 594px;
  top: 2894px;
  width: 439px;
  height: 99px;
}

div.road-map-36 .part10 {
  position: absolute;
  left: 1033px;
  top: 2894px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_36.jpg);
}

div.road-map-36 .current10 {
  position: absolute;
  left: 1033px;
  top: 2894px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_36.png);
  background-color: #4d8b3e;
}

div.road-map-36 .done10 {
  position: absolute;
  left: 1033px;
  top: 2894px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-10.jpg);
}

div.road-map-37 {
  position: absolute;
  left: 1478px;
  top: 2894px;
  width: 41px;
  height: 4964px;
}

div.road-map-38 {
  position: absolute;
  left: 60px;
  top: 2993px;
  width: 331px;
  height: 387px;
}

div.road-map-39 .part11 {
  position: absolute;
  left: 391px;
  top: 2993px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_39.jpg);
}

div.road-map-39 .current11 {
  position: absolute;
  left: 391px;
  top: 2993px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_39.png);
  background-color: #4d8b3e;
}

div.road-map-39 .done11 {
  position: absolute;
  left: 391px;
  top: 2993px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-11.jpg);
}

div.road-map-40 {
  position: absolute;
  left: 836px;
  top: 2993px;
  width: 197px;
  height: 415px;
}

div.road-map-41 {
  position: absolute;
  left: 1033px;
  top: 3180px;
  width: 445px;
  height: 228px;
}

div.road-map-42 {
  position: absolute;
  left: 391px;
  top: 3279px;
  width: 445px;
  height: 101px;
}

div.road-map-43 {
  position: absolute;
  left: 60px;
  top: 3380px;
  width: 14px;
  height: 4478px;
}

div.road-map-44 .part12 {
  position: absolute;
  left: 74px;
  top: 3380px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_44.jpg);
}

div.road-map-44 .current12 {
  position: absolute;
  left: 74px;
  top: 3380px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_44.png);
  background-color: #4d8b3e;
}

div.road-map-44 .done12 {
  position: absolute;
  left: 74px;
  top: 3380px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-12.jpg);
}

div.road-map-45 {
  position: absolute;
  left: 519px;
  top: 3380px;
  width: 317px;
  height: 28px;
}

div.road-map-46 {
  position: absolute;
  left: 519px;
  top: 3408px;
  width: 298px;
  height: 353px;
}

div.road-map-47 .part13 {
  position: absolute;
  left: 817px;
  top: 3408px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_47.jpg);
}

div.road-map-47 .current13 {
  position: absolute;
  left: 817px;
  top: 3408px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_47.png);
  background-color: #4d8b3e;
}

div.road-map-47 .done13 {
  position: absolute;
  left: 817px;
  top: 3408px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-13.jpg);
}

div.road-map-48 {
  position: absolute;
  left: 1262px;
  top: 3408px;
  width: 216px;
  height: 740px;
}

div.road-map-49 {
  position: absolute;
  left: 74px;
  top: 3666px;
  width: 445px;
  height: 95px;
}

div.road-map-50 {
  position: absolute;
  left: 817px;
  top: 3694px;
  width: 445px;
  height: 67px;
}

div.road-map-51 {
  position: absolute;
  left: 74px;
  top: 3761px;
  width: 390px;
  height: 384px;
}

div.road-map-52 .part14 {
  position: absolute;
  left: 464px;
  top: 3761px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_52.jpg);
}

div.road-map-52 .current14 {
  position: absolute;
  left: 464px;
  top: 3761px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_52.png);
  background-color: #4d8b3e;
}

div.road-map-52 .done14 {
  position: absolute;
  left: 464px;
  top: 3761px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-14.jpg);
}

div.road-map-53 {
  position: absolute;
  left: 909px;
  top: 3761px;
  width: 353px;
  height: 387px;
}

div.road-map-54 {
  position: absolute;
  left: 464px;
  top: 4047px;
  width: 445px;
  height: 98px;
}

div.road-map-55 {
  position: absolute;
  left: 74px;
  top: 4145px;
  width: 39px;
  height: 507px;
}

div.road-map-56 .part15 {
  position: absolute;
  left: 113px;
  top: 4145px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_56.jpg);
}

div.road-map-56 .current15 {
  position: absolute;
  left: 113px;
  top: 4145px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_56.png);
  background-color: #4d8b3e;
}

div.road-map-56 .done15 {
  position: absolute;
  left: 113px;
  top: 4145px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_box-15.jpg);
}

div.road-map-57 {
  position: absolute;
  left: 559px;
  top: 4145px;
  width: 350px;
  height: 3px;
  background-image: url(/src/images/road-map_57.jpg);
}

div.road-map-58 {
  position: absolute;
  left: 559px;
  top: 4148px;
  width: 277px;
  height: 972px;
}

div.road-map-59 .part16 {
  position: absolute;
  left: 836px;
  top: 4148px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_59.jpg);
}

div.road-map-59 .current16 {
  position: absolute;
  left: 836px;
  top: 4148px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_59.png);
  background-color: #4d8b3e;
}

div.road-map-59 .done16 {
  position: absolute;
  left: 836px;
  top: 4148px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_box-16.jpg);
}

div.road-map-60 {
  position: absolute;
  left: 1282px;
  top: 4148px;
  width: 196px;
  height: 394px;
}

div.road-map-61 {
  position: absolute;
  left: 113px;
  top: 4431px;
  width: 446px;
  height: 221px;
}

div.road-map-62 {
  position: absolute;
  left: 836px;
  top: 4434px;
  width: 446px;
  height: 108px;
}

div.road-map-63 {
  position: absolute;
  left: 836px;
  top: 4542px;
  width: 119px;
  height: 578px;
}

div.road-map-64 .part17 {
  position: absolute;
  left: 955px;
  top: 4542px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_64.jpg);
}

div.road-map-64 .current17 {
  position: absolute;
  left: 955px;
  top: 4542px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_64.png);
  background-color: #4d8b3e;
}

div.road-map-64 .done17 {
  position: absolute;
  left: 955px;
  top: 4542px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-17.jpg);
}

div.road-map-65 {
  position: absolute;
  left: 1400px;
  top: 4542px;
  width: 78px;
  height: 753px;
}

div.road-map-66 {
  position: absolute;
  left: 74px;
  top: 4652px;
  width: 24px;
  height: 1285px;
}

div.road-map-67 .part18 {
  position: absolute;
  left: 98px;
  top: 4652px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_67.jpg);
}

div.road-map-67 .current18 {
  position: absolute;
  left: 98px;
  top: 4652px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_67.png);
  background-color: #4d8b3e;
}

div.road-map-67 .done18 {
  position: absolute;
  left: 98px;
  top: 4652px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-18.jpg);
}

div.road-map-68 {
  position: absolute;
  left: 543px;
  top: 4652px;
  width: 16px;
  height: 468px;
}

div.road-map-69 {
  position: absolute;
  left: 955px;
  top: 4828px;
  width: 445px;
  height: 467px;
}

div.road-map-70 {
  position: absolute;
  left: 98px;
  top: 4938px;
  width: 445px;
  height: 182px;
}

div.road-map-71 {
  position: absolute;
  left: 98px;
  top: 5120px;
  width: 316px;
  height: 817px;
}

div.road-map-72 .part19 {
  position: absolute;
  left: 414px;
  top: 5120px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_72.jpg);
}

div.road-map-72 .current19 {
  position: absolute;
  left: 414px;
  top: 5120px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_72.png);
  background-color: #4d8b3e;
}

div.road-map-72 .done19 {
  position: absolute;
  left: 414px;
  top: 5120px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-19.jpg);
}

div.road-map-73 {
  position: absolute;
  left: 859px;
  top: 5120px;
  width: 96px;
  height: 440px;
}

div.road-map-74 {
  position: absolute;
  left: 955px;
  top: 5295px;
  width: 40px;
  height: 766px;
}

div.road-map-75 .part20 {
  position: absolute;
  left: 995px;
  top: 5295px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_75.jpg);
}

div.road-map-75 .current20 {
  position: absolute;
  left: 995px;
  top: 5295px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_75.png);
  background-color: #4d8b3e;
}

div.road-map-75 .done20 {
  position: absolute;
  left: 995px;
  top: 5295px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-20.jpg);
}

div.road-map-76 {
  position: absolute;
  left: 1440px;
  top: 5295px;
  width: 38px;
  height: 2563px;
}

div.road-map-77 {
  position: absolute;
  left: 414px;
  top: 5406px;
  width: 445px;
  height: 154px;
}

div.road-map-78 {
  position: absolute;
  left: 414px;
  top: 5560px;
  width: 72px;
  height: 377px;
}

div.road-map-79 .part21 {
  position: absolute;
  left: 486px;
  top: 5560px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_79.jpg);
}

div.road-map-79 .current21 {
  position: absolute;
  left: 486px;
  top: 5560px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_79.png);
  background-color: #4d8b3e;
}

div.road-map-79 .done21 {
  position: absolute;
  left: 486px;
  top: 5560px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-21.jpg);
}

div.road-map-80 {
  position: absolute;
  left: 931px;
  top: 5560px;
  width: 24px;
  height: 501px;
}

div.road-map-81 {
  position: absolute;
  left: 995px;
  top: 5581px;
  width: 445px;
  height: 480px;
}

div.road-map-82 {
  position: absolute;
  left: 486px;
  top: 5846px;
  width: 445px;
  height: 91px;
}

div.road-map-83 {
  position: absolute;
  left: 74px;
  top: 5937px;
  width: 15px;
  height: 1921px;
}

div.road-map-84 .part22 {
  position: absolute;
  left: 89px;
  top: 5937px;
  width: 446px;
  height: 287px;
  background-image: url(/src/images/road-map_84.jpg);
}
div.road-map-84 .current22 {
  position: absolute;
  left: 89px;
  top: 5937px;
  width: 446px;
  height: 287px;
  background-image: url(/src/images/road-map_84.png);
  background-color: #4d8b3e;
}

div.road-map-84 .done22 {
  position: absolute;
  left: 89px;
  top: 5937px;
  width: 446px;
  height: 287px;
  background-image: url(/src/images/road-map_box-22.jpg);
}

div.road-map-85 {
  position: absolute;
  left: 535px;
  top: 5937px;
  width: 396px;
  height: 124px;
}

div.road-map-86 {
  position: absolute;
  left: 535px;
  top: 6061px;
  width: 324px;
  height: 823px;
}

div.road-map-87 .part23 {
  position: absolute;
  left: 859px;
  top: 6061px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_87.jpg);
}

div.road-map-87 .current23 {
  position: absolute;
  left: 859px;
  top: 6061px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_87.png);
  background-color: #4d8b3e;
}

div.road-map-87 .done23 {
  position: absolute;
  left: 859px;
  top: 6061px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-23.jpg);
}

div.road-map-88 {
  position: absolute;
  left: 1304px;
  top: 6061px;
  width: 136px;
  height: 1797px;
}

div.road-map-89 {
  position: absolute;
  left: 89px;
  top: 6224px;
  width: 446px;
  height: 200px;
}

div.road-map-90 {
  position: absolute;
  left: 859px;
  top: 6347px;
  width: 445px;
  height: 537px;
}

div.road-map-91 .part24 {
  position: absolute;
  left: 89px;
  top: 6424px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_91.jpg);
}

div.road-map-91 .current24 {
  position: absolute;
  left: 89px;
  top: 6424px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_91.png);
  background-color: #4d8b3e;
}

div.road-map-91 .done24 {
  position: absolute;
  left: 89px;
  top: 6424px;
  width: 446px;
  height: 286px;
  background-image: url(/src/images/road-map_box-24.jpg);
}

div.road-map-92 {
  position: absolute;
  left: 89px;
  top: 6710px;
  width: 446px;
  height: 1148px;
}

div.road-map-93 {
  position: absolute;
  left: 535px;
  top: 6884px;
  width: 57px;
  height: 974px;
}

div.road-map-94 .part25 {
  position: absolute;
  left: 592px;
  top: 6884px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_94.jpg);
}

div.road-map-94 .current25 {
  position: absolute;
  left: 592px;
  top: 6884px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_94.png);
  background-color: #4d8b3e;
}

div.road-map-94 .done25 {
  position: absolute;
  left: 592px;
  top: 6884px;
  width: 445px;
  height: 286px;
  background-image: url(/src/images/road-map_box-25.jpg);
}

div.road-map-95 {
  position: absolute;
  left: 1037px;
  top: 6884px;
  width: 267px;
  height: 974px;
}

div.road-map-96 {
  position: absolute;
  left: 592px;
  top: 7170px;
  width: 445px;
  height: 688px;
}
.time {
  display: flex;
  flex-direction: column;
  position: sticky;
  bottom: 0;
  background-color: #ef233c;
  height: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.time__first {
  color: #fff;
}
.time__first,
.time__second {
  margin-bottom: 0 !important;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
a.bandeau {
  color: inherit;
  text-decoration: none;
}

.finished {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.664);
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 9000;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.finished__text {
  font-size: 2.5rem;
  text-align: center;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");

.count {
  display: inline;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: white !important;
  margin-bottom: 0 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 1440px) {
  .izy_rehetra {
    zoom: 0.95;
    margin: 0;
  }
  div {
    resize: both;
  }
  div.Table_01 {
    zoom: 100%;
  }
  .time {
    height: auto;
    font-size: 10px;
    flex-direction: column;
  }

  .time__first,
  .time__second {
    margin-bottom: 0 !important;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
  .count {
    display: block;
  }
  .finished__text {
    text-align: center;
    font-size: 1.5rem;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 868px) {
  .izy_rehetra {
    zoom: 0.24;
    margin: 0;
  }
  div {
    resize: both;
  }
  div.Table_01 {
    zoom: 100%;
  }
  #tsparticles {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
  }
}
