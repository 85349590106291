.popupLanding {
  position: fixed;
  flex-direction: column;
  display: flex;
  margin: 0;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease;
  min-width: 600px;
  min-height: 400px;
  padding: 0px;
  box-shadow: 0 50px 30px rgba(0, 0, 0, 0.3);
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  border-radius: 10px;
}

.popupLanding.active {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
  z-index: 8000;
  pointer-events: auto;
}

.form {
  width: 50%;
  position: relative;
  height: 50px;
  background-color: red;
  overflow: hidden;
}

.form input {
  width: 100%;
  height: 100%;
  color: #5c6277;
  padding-top: 20px;
  border: none;
  outline: none;
}

.form label {
  position: absolute;
  bottom: -5px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid black;
}

.form label:after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -1px;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid #5fa8d3;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.3s ease;
}

.form input:focus + .label-name .content-name,
.form input:valid + .label-name .content-name {
  transform: translateY(-100%);
  font-size: 12px;
  color: #5fa8d3;
}

.form input:focus + .label-name::after,
.form input:valid + .label-name::after {
  transform: translateX(0%);
}
