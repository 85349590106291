@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
/* custom scroll bar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar:hover {
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::selection {
  background: rgb(0, 123, 255, 0.3);
}
.content {
  max-width: 1250px;
  margin: auto;
  padding: 0 30px;
}
.navbar26 {
  position: fixed;
  width: 100%;
  z-index: 2;
  padding: 25px 0;
  transition: all 0.3s ease;
  z-index: 8000;
}
.navbar26.sticky {
  background: #d5f2f1;
  padding: 10px 0;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
}

.navbar26 .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar26 .logo a {
  color: #1b1b1b;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}

.menu-list li {
  list-style: none;
}

.menu-list li:hover {
  opacity: 0.5;
}
.menu-list li a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-left: 25px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.menu-list li a:hover {
  color: #007bff;
}

.icon {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.menu-list .cancel-btn {
  position: absolute;
  right: 30px;
  top: 20px;
}
@media (min-width: 1230px) {
  .content {
    padding: 0 60px;
  }

  body.disabled {
    overflow: hidden;
  }
  .icon {
    display: block;
  }
  .icon.hide {
    display: none;
  }
  .navbar26 .menu-list {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 400px;
    left: -100%;
    top: 0px;
    display: block;
    padding: 40px 0;
    text-align: center;
    background: #222;
    transition: all 0.3s ease;
  }
  .navbar26.show .menu-list {
    left: 0%;
    pointer-events: all;
  }
  .navbar26 .menu-list li {
    margin-top: 45px;
  }
  .navbar26 .menu-list li a {
    font-size: 23px;
    margin-left: -100%;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar26.show .menu-list li a {
    margin-left: 0px;
  }
}
@media (max-width: 1100px) {
  .content {
    padding: 0 40px;
  }
}
@media (max-width: 900px) {
  .content {
    padding: 0 30px;
  }
}
@media (max-width: 868px) {
  body.disabled {
    overflow: hidden;
  }
  .icon {
    display: block;
  }
  .icon.hide {
    display: none;
  }
  .navbar26 .menu-list {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 400px;
    left: -100%;
    top: 0px;
    display: block;
    padding: 40px 0;
    text-align: center;
    background: #222;
    transition: all 0.3s ease;
  }
  .navbar26.show .menu-list {
    left: 0%;
  }
  .navbar26 .menu-list li {
    margin-top: 45px;
  }
  .navbar26 .menu-list li a {
    font-size: 23px;
    margin-left: -100%;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar26.show .menu-list li a {
    margin-left: 0px;
  }
}
@media (max-width: 380px) {
  .navbar26 .logo a {
    font-size: 27px;
  }
}
