.window {
  width: 500px;
  height: 300px;
  position: relative;
  top: 66px;
  left: 10%;
  background-image: url(/src/spin.jpg);
  border-radius: 6px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.8);
}

#blur.active {
  filter: blur(20px);

  pointer-events: none;
  user-select: none;
}

body.disabled {
  overflow: hidden;
  pointer-events: none;
}

body.disabled:not(.popups.active),
body.disabled:not(.background_popup) {
  pointer-events: all;
}

*.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

.background {
  position: fixed;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  cursor: zoom-in;
  perspective: 800px;
  left: 50%;
  margin: 0;
  padding: 10px;
  background: transparent;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  visibility: hidden;
  overflow: hidden;
  z-index: -1000;
  opacity: 0;
}

.background.alefa {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
  z-index: 8000;
  pointer-events: auto;
}

.popups {
  position: fixed;
  flex-direction: column;
  display: flex;
  width: 600px;
  height: auto;
  justify-content: center;
  cursor: pointer;
  perspective: 800px;
  left: 50%;
  margin: 0;
  padding: 10px;
  background: #fff;
  transform: translate(-50%, -50%);
  transition: transform 0.5s ease;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  visibility: hidden;
  overflow: hidden;
  z-index: -1000;
  opacity: 0;
}

.popups.active {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
  z-index: 8000;
  pointer-events: auto;
}

.window__video {
  width: 100%;
  height: auto;
  position: relative;
  background-color: none;
  border-radius: 25px;
  margin-bottom: 20px;
}

.notToday {
  position: relative;
  display: inline-block;
  font-size: 4vw;
  color: #fff;
}

.text {
  position: relative;
  display: inline-block;
  animation: bounce 1s ease infinite;
  font-size: 4vw;
  color: #fff;
}
.img {
  position: relative;
  pointer-events: none;
}
.sary {
  position: relative;
}

.title {
  font-size: 0.9em;
}

@media (prefers-reduced-motion: no-preference) {
  .img {
    animation: img-spin infinite 0.5s linear;
  }
}

@keyframes img-spin {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes bounce {
  20%,
  50%,
  80%,
  to {
    transform: translateY(0);
    animation-delay: 1000s;
  }
  40% {
    transform: translateY(-20px);
    animation-delay: 0.2s;
  }
  70% {
    transform: translateY(-10px);
    animation-delay: 0.3s;
  }

  90% {
    transform: translateY(-4px);
    animation-delay: 0.5s;
  }
}
.btn {
  border-radius: 50px;
}
.link {
  background-color: #14b0b7;
  text-decoration: none;
}

.link:hover {
  background-color: white;
  color: #14b0b7 !important;
  text-decoration: none;
}
@media (max-width: 1440px) {
  .popups {
    zoom: 100%;
    display: block;
    width: 768px;
    height: auto;
    padding-top: 0;
  }
  .text {
    font-size: 50px;
  }
  .notToday {
    font-size: 50px;
  }
  .title {
    font-size: 12px;
  }
  .window__video {
    width: auto;
    height: auto;
    margin-bottom: 20px;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
}
@media (max-width: 868px) {
  .popups {
    zoom: 100%;
    display: block;
    width: 70%;
    height: auto;
    padding-top: 0;
  }

  .title {
    font-size: 12px;
  }
  .text {
    font-size: 4vw;
  }
  .notToday {
    font-size: 4vw;
  }
  .window__video {
    width: auto;
    height: auto;
    margin-bottom: 20px;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
}

.social {
  display: flex;
  justify-content: center;
}

.social button {
  margin: 10px;
  outline: none;
}

.social button:hover {
  opacity: 0.9;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.modal-header {
  border-bottom: 0 none !important;
}

.modal-footer {
  border-top: 0 none !important;
}

.modal.show {
  position: fixed !important;
  background-color: transparent !important;
}
